<template>
  <div id="videoLesson">
    <section class="headNavInfo">
      <div class="headNavInfo_title">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/practice' }">学练考</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/practice/lesson' }">课程</el-breadcrumb-item>
          <el-breadcrumb-item>视频</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </section>
    <!-- 视频 -->
    <section class="videoBox">
      <video :src="videoURL" controls class="video" />
      <!-- 顶部栏 -->
      <div class="videoInnerBox_top">
        <div class="top_menu hoverBlue" @click="showMenu">
          <div class="menu_icon">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-mulu"></use>
            </svg>
          </div>
          <div class="menu_text">目录</div>
        </div>
        <div class="top_action">
          <div class="action_title">{{ videoObj.courseName }}</div>
          <div class="action_iconGroup">
            <!-- 点赞 -->
            <div class="action_groupItem" @click="setFavorite">
              <div class="action_icon" v-if="isFavorite === false">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-dianzan"></use>
                </svg>
              </div>
              <div class="action_icon" v-else>
                <svg class="icon" aria-hidden="true" style="color: #db3b3d">
                  <use xlink:href="#icon-dianzan_active"></use>
                </svg>
              </div>
              <div class="action_text">{{ isFavorite ? "已点赞" : "点赞" }}</div>
            </div>
            <!-- 分享 -->
            <div class="action_groupItem" @click="setShare">
              <div class="action_icon">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-fenxiang"></use>
                </svg>
              </div>
              <div class="action_text">分享</div>
            </div>
            <!-- 收藏 -->
            <div class="action_groupItem" @click="setCollect(videoObj.courseCode)">
              <div class="action_icon" v-if="!isCollect ">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-shoucang"></use>
                </svg>
              </div>
              <div class="action_icon" v-else>
                <svg class="icon" aria-hidden="true" style="color: #db3b3d">
                  <use xlink:href="#icon-shoucang_active"></use>
                </svg>
              </div>
              <div class="action_text">{{ isCollect ? "已收藏" : "收藏" }}</div>
            </div>
          </div>
        </div>
        <div class="top_word hoverBlue" @click="openWord">弹幕列表</div>
      </div>
      <!-- 目录 -->
      <div class="videoInnerBox_list leftBox" v-show="isOpenMenu">
        <div class="list_title hoverBlue" @click="isOpenMenu = false"></div>
        <div class="list_content">
          <template v-for="(item, index) in sectionList">
            <el-tooltip placement="bottom" :key="index">
              <div slot="content">{{ item.courseChapterName }}</div>
              <div class="content_item" @click="choiceSection(item)">{{ item.courseChapterName }}</div>
            </el-tooltip>
          </template>
        </div>
      </div>
      <!-- 弹幕 -->
      <div class="videoInnerBox_list rightBox" v-show="isOpenWord">
        <div class="list_title"></div>
        <div class="list_content" style="padding: 0 10px 10px">
          <div class="rightContent_title">
            <!-- <div class="rightContent_videoTime">时间</div> -->
            <div class="rightContent_word">弹幕内容</div>
            <div class="rightContent_sendTime">发送时间</div>
          </div>
          <template v-for="(item, index) in videoWord">
            <div class="rightContent_item hoverBlue" :key="index">
              <!-- <div class="rightContent_videoTime">{{ item.videoTime }}</div> -->
              <el-tooltip placement="bottom">
                <div slot="content">{{ item.comment }}</div>
                <div class="rightContent_word">{{ item.comment }}</div>
              </el-tooltip>
              <div class="rightContent_sendTime">{{ item.createDt | dataFormat("m-d,h-m") }}</div>
            </div>
          </template>
        </div>
      </div>
    </section>
    <!-- 简介 -->
    <section class="videoInfo">
      <div class="videoInfo_author">
        <div class="author_logo">
          <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" :size="50"></el-avatar>
        </div>
        <div class="author_info">
          <div>{{ videoObj.teacherName }}</div>
          <div>{{ videoObj.teacherIntroduce }}</div>
        </div>
      </div>
      <div class="videoInfo_lesson">
        <div class="lesson_title">课程简介：</div>
        <div class="lesson_content">
          {{ videoObj.briefIntroduction }}
        </div>
      </div>
    </section>

    <Comments @pushNewComments="pushNewComments" />
  </div>
</template>

<script>
import Comments from "@/views/practice/lesson/components/Comments";
export default {
  components: {
    Comments,
  },
  computed: {},
  data () {
    return {
      courseCode: "",
      // 章节
      sectionList: [],
      //   弹幕
      videoWord: [

      ],
      //   是否打开目录
      isOpenMenu: false,
      //   是否打开弹幕
      isOpenWord: false,
      //   是否点赞
      isFavorite: false,
      // 是否收藏
      isCollect: false,

      videoObj: {},

      videoURL: ''

    };
  },
  methods: {
    //   打开目录列表
    showMenu () {
      this.isOpenMenu = true;
    },
    // 打开弹幕列表
    openWord () {
      this.isOpenWord = !this.isOpenWord;
    },
    //   点赞
    setFavorite () {
      if (this.isFavorite == false) {
        this._handleCourseUp();
      } else {
        this.$message({
          message: "已经点赞过了哦",
          type: "info",
          offset:'400',
        });
      }
    },
    // 收藏
    setCollect (courseCode) {

      this.$api.lesson.addCollect({
        memberCode: this.memberCode,
        courseCode
      }).then(res => {
        if (res.data.code === 200) {
          this.isCollect = !this.isCollect
        }
      })


    },
    // 分享
    setShare () {

      if (!this.videoURL) {
        // this.$message.error('该内容暂不可分享')
        this.$message({
            message: "该内容暂不可分享!",
            type: "error",
            offset:'400'
          });
        return
      }

      //创建input标签
      let input = document.createElement('input')
      //将input的值设置为需要复制的内容
      input.value = this.videoURL;
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //移除input标签
      document.body.removeChild(input)
      // this.$message.success('视频地址复制成功')
      this.$message({
            message: "视频地址复制成功!",
            type: "success",
            offset:'400'
          });
    },

    choiceSection (item) {
      //选择视频
      this.videoURL = item.videoURL;
    },


    _getVideo () {
      //获取课程详情
      this.$api.lesson
        .queryOneCourse({
          courseCode: this.courseCode,
          memberCode: this.memberCode,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.videoObj = res.data.data;
            this.isCollect = res.data.data.isCollent
          }
        });
    },

    _getVideoIsup () {
      //获取是否已点赞
      this.$api.lesson
        .getVideoIsup({
          courseCode: this.courseCode,
          memberCode: this.memberCode,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.isFavorite = res.data.data.isUp === "1" ? true : false;
          }
        });
    },

    _handleCourseUp () {
      //点赞
      this.$api.lesson
        .handleCourseUp({
          courseCode: this.courseCode,
          memberCode: this.memberCode,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.isFavorite = !this.isFavorite
          }
        });
    },

    _getVideoSections () {
      //获取章节
      this.$api.lesson
        .getVideoSections({
          courseCode: this.courseCode,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.sectionList = res.data.data;
            if (this.sectionList.length > 0) {
              this.videoURL = this.sectionList[0].videoURL
            }

          }
        });
    },


    _getCommentsList () {
      // 获取 评论列表
      this.$api.lesson
        .getComments({
          currPage: 1,
          pageSize: 100,
          reorder: "2",
          condition: {
            courseCode: this.courseCode,
          }
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.videoWord = res.data.data.data;
          }
        });
    },


    pushNewComments (item) {
      //新增弹幕
      this.videoWord.push(item)
    }
  },

  created () {
    this.courseCode = this.$route.query.courseCode;
    this.memberCode = JSON.parse(sessionStorage.getItem("userInfo")).memberCode;
    this._getVideo();
    this._getVideoIsup();
    this._getVideoSections();
    this._getCommentsList();
  },
};
</script>

<style lang="less" scoped>
.videoBox {
  position: relative;
  width: 100%;
  height: 610px;
  margin-top: 35px;
  .video {
    width: 100%;
    height: 100%;
  }
  .videoInnerBox_top {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    height: 40px;
    width: 100%;
    align-items: center;
    color: white;
    background: rgba(25, 24, 24, 0.8);
    z-index: 1;
    .top_menu {
      display: flex;
      height: 100%;
      width: 95px;
      align-items: center;
      justify-content: center;

      .menu_icon {
        margin-right: 10px;
        font-size: 18px;
      }
      .menu_text {
        font-size: 16px;
      }
    }
    .top_action {
      display: flex;
      flex: 1;
      height: 100%;
      padding: 0 10px 0 20px;
      align-items: center;
      justify-content: space-between;
      .action_title {
        width: 410px;
        font-size: 16px;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .action_iconGroup {
        display: flex;
        align-items: center;
        font-size: 12px;
        .action_groupItem {
          display: flex;
          margin-left: 20px;
          align-items: center;
          cursor: pointer;
          .action_icon {
            margin-right: 3px;
            font-size: 12px;
          }
          .action_text {
            font-size: 12px;
          }
        }
        & .action_groupItem:nth-child(2) {
          .action_icon {
            font-size: 10px;
          }
        }
      }
    }
    .top_word {
      width: 240px;
      padding-left: 10px;
      font-size: 15px;
      text-align: left;
    }
  }
  .leftBox {
    left: 0;
    z-index: 2;
  }
  .rightBox {
    right: 0;
    z-index: 0;
  }
  .rightContent_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .rightContent_videoTime {
    width: 59px;
    margin-right: 6px;
    text-align: left;
  }
  .rightContent_word {
    width: 130px;
    margin-right: 5px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .rightContent_sendTime {
    width: 85px;
    text-align: left;
  }
  .rightContent_item {
    display: flex;
    align-items: center;
  }
}
.videoInnerBox_list {
  position: absolute;
  top: 0;
  width: 240px;
  height: 540px;
  color: white;
  font-size: 12px;
  background: black;
  .list_title {
    height: 40px;
    width: 100%;
    padding: 10px 10px;
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    background: transparent;
  }
  .list_content {
    height: 495px;
    padding: 0 20px 10px;
    overflow-y: auto;
    .content_item {
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    & .content_item:nth-child(n + 2) {
      margin-top: 12px;
    }
    .content_item:hover {
      color: #47bcffff;
      cursor: pointer;
    }
  }
  .list_content::-webkit-scrollbar {
    width: 4px;
  }
  .list_content::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: white;
  }
}
.videoInfo {
  margin-top: 30px;
  .videoInfo_author {
    display: flex;
    align-items: center;
    text-align: left;
    .author_logo {
      margin-right: 13px;
    }
    .author_info {
      & div:first-child {
        font-size: 16px;
        font-weight: bold;
        color: #191818ff;
      }
      & div:last-child {
        font-size: 13px;
        color: #7f7f7fff;
      }
    }
  }
  .videoInfo_lesson {
    width: 100%;
    padding: 16px;
    margin-top: 20px;
    background: #f9fbfcff;
    text-align: left;
    .lesson_title {
      font-size: 18px;
      font-weight: bold;
      color: #191818ff;
    }
    .lesson_content {
      font-size: 14px;
      color: #7f7f7fff;
    }
  }
}
.hoverBlue:hover {
  color: #47bcffff;
  cursor: pointer;
}
</style>
