<template>
  <div>
    <div class="total">{{ count }} 评论</div>
    <div class="content flex">
      <div style="margin-right: 20px">
        <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
      </div>
      <div class="flex1">
        <el-input style="width: 100%" type="textarea" placeholder="请输入内容" v-model="textarea"
          :autosize="{ minRows: 4, maxRows: 4 }">
        </el-input>
        <div style="text-align: right">
          <el-button type="danger" @click="submit">发布</el-button>
        </div>
      </div>
    </div>
    <div class="detail">
      <div class="flex sort">
        <span :class="[isHot ? 'theme-c' : 'black', 'pointer']" @click="changeSort('1')">按热度排序</span>
        <span :class="[!isHot ? 'theme-c' : 'black', 'pointer']" @click="changeSort('2')">按时间排序</span>
      </div>

      <div class="comments flex" v-for="item in list" :key="item.id">
        <div style="margin-right: 20px">
          <el-avatar :src="item.logo"></el-avatar>
        </div>
        <div class="flex1 com-d">
          <div class="name">
            {{ item.memberName }}
          </div>
          <div class="com-c">
            {{ item.comment }}
          </div>
          <div class="footer flex justify-between">
            <span>{{ item.createDt | dataFormat }}</span>
            <span @click="commonUp(item)" class="pointer">

              <svg class="icon" aria-hidden="true" v-if="item.isUp" style="color: #db3b3d">
                <use xlink:href="#icon-dianzan_active"></use>
              </svg>
              <svg class="icon" aria-hidden="true" v-else>
                <use xlink:href="#icon-dianzan"></use>
              </svg>
              {{ item.upNum }}</span>

          </div>
        </div>
      </div>
    </div>

    <div class="pageInfo">
      <el-pagination background @current-change="handleCurrentChange" :current-page.sync="queryInfo.currPage"
        :page-size="queryInfo.pageSize" layout=" prev, pager, next,total, jumper" :total="count">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      reorder: "1",
      list: [],
      textarea: "",
      isHot: true,
      // 分页参数
      queryInfo: {
        currPage: 1,
        pageSize: 10,
      },
      count: 0,
      courseCode: "",
      memberCode: ""
    };
  },
  methods: {
    changeSort (val) {
      //修改排序
      this.isHot = val === "1" ? true : false;
      this.reorder = val;
      this.getList();
    },

    // 修改当前页
    handleCurrentChange (newCurr) {
      this.queryInfo.currPage = newCurr;
      this.getList()
    },

    getList () {
      // 获取 评论列表
      this.$api.lesson.handleComments({
        currPage: this.queryInfo.currPage,
        pageSize: this.queryInfo.pageSize,
        reorder: this.reorder,
        condition: {
          courseCode: this.courseCode,
        },
        memberCode: this.memberCode,
      })
        .then((res) => {
          if (res.data.code === 200) {
            this.count = res.data.data.count;
            this.list = res.data.data.data;
          }
        });
    },

    submit () {
      //提交
      this.$api.lesson
        .handleComment({
          courseCode: this.courseCode,
          memberCode: this.memberCode,
          comment: this.textarea,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.textarea = "";
            // this.$message.success("提交成功");
             this.$message({
            message: "提交成功!",
            type: "success",
            offset:'400'
          });
            this.getList();

            let temp = {
              createDt: res.data.data.createDt,
              comment: res.data.data.comment
            }
            this.$emit('pushNewComments', temp)
          }
        });
    },


    commonUp (item) {
      //点赞
      this.$api.lesson.commentUp({
        commentCode: item.commentCode,
        memberCode: this.memberCode,
      }).then(res => {
        if (res.data.code === 200) {
          this.getList()
        }
      })
    }
  },

  created () {
    this.memberCode = JSON.parse(sessionStorage.getItem('userInfo')).memberCode
    this.courseCode = this.$route.query.courseCode;
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.black {
  color: #000;
}
.total {
  text-align: left;
  font-size: 18px;
  margin-top: 50px;
}
.content {
  margin: 30px 0;
  .el-button--danger {
    height: 28px;
    width: 69px;
    background-color: #db3b3d;
    border-color: #db3b3d;
  }
  .el-button {
    margin-top: 10px;
    line-height: 0;
  }
}
.detail {
  .sort {
    font-size: 18px;
    border-bottom: 1px solid #c1c4cc;
    padding-bottom: 17px;
    span:nth-child(1) {
      margin-right: 35px;
    }
  }
}
.comments {
  padding: 30px 0;
  .com-d {
    text-align: left;
    .name {
      color: #7f7f7f;
      font-size: 18px;
      margin-top: 10px;
    }
    .com-c {
      font-size: 16px;
      margin: 5px 0 30px 0;
    }
  }
  border-bottom: 1px solid #c1c4cc;
}
.pageInfo {
  margin-top: 50px;
}
.footer {
  .icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url('../../../../assets/img/com/icon-3.png');
    background-size: cover;
  }
}
</style>
